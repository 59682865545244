export function uploadFileToPresignedUrl(
  link: string,
  file: File,
  onProgress: (progress: number, mb: number) => void
): Promise<void> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', link, true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        const uploadedMB = event.loaded / (1024 * 1024);
        onProgress(percentComplete, uploadedMB);
      }
    };

    // Set up event listeners to handle completion and errors
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve();
      } else {
        reject(new Error(`Upload failed with status ${xhr.status}: ${xhr.statusText}`));
      }
    };

    xhr.onerror = () => {
      reject(new Error('An error occurred during the file upload.'));
    };

    xhr.send(file);
  });
}
